@import 'src/variables';

.modal {
  :global(.ant-modal) {
    max-width: 58.75rem;
    width: 95% !important;

    @media (max-width: $tablet) {
      max-width: 17.5rem;
    }
  }

  :global(.ant-modal-content) {
    border-radius: 8px;
  }
}

.modalBody {
  padding-bottom: 2rem;

  @media (max-width: $tablet) {
    padding-bottom: 0;
  }

  p {
    text-align: center;
    font-size: 1rem;

    @media (max-width: $tablet) {
      margin-bottom: 1.5rem;
    }
  }
}