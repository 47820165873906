@import 'src/variables';
@import 'src/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 440px;
  max-height: 440px;
  width: 100%;
  height: 440px;
  background-color: $white;

  @media (max-width: $mobile) {
    flex-direction: row;
    max-width: 100%;
    height: 105px;
  }
  pointer-events: none;
}

.tourCardSpinner {
  margin-top: 36%;
  margin-left: 44%;
  @media (max-width: $mobile) {
    margin-top: 14%;
    margin-left: 36%;
  }
}
.tourImageWrapper {
  position: relative;
  width: 100%;
  height: 340px;

  @media (max-width: $mobile) {
    width: 45%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;

    @media (max-width: $mobile) {
      border-radius: 0 0 0 10px;
    }
  }

  .overflow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .shareButton {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      padding: 0;
      width: 42px;
      height: 42px;
      background: none;
      cursor: pointer;
      background: #fff;
      border-radius: 8px;

      @media (max-width: $mobile) {
        top: unset;
        bottom: 0.5rem;
        left: 0.5rem;
        width: 32px;
        height: 32px;
      }

      img {
        width: 25px;
        object-fit: contain;
        @media (max-width: $mobile) {
          width: 18px;
        }
      }
    }

    .tourSettingsButton {
      background: none;
      border: none;
      float: right;
      margin-top: 7px;
      margin-right: 6px;
      filter: brightness(4) saturate(0);
    }

    .eyeButton {
      position: absolute;
      left: 0.5rem;
      bottom: 0.5rem;
      border: none;
      background: transparent;

      @media (max-width: $mobile) {
        left: unset;
        right: 0.5rem;
      }
    }

    .toggleWrapper {
      position: absolute;
      display: flex;
      width: 35px;
      height: 50px;
      background: $white;
      border-radius: 0 0 16px 0;
      display: none;
    }

    .proLabel {
      position: absolute;
      top: 0.5rem;
      left: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 82px;
      height: 23px;
      background: $white;
      color: $pink;
      font-size: 12px;
      font-weight: bold;
      border-radius: 4px;
      cursor: default;

      &:before {
        content: '';
        margin-right: 2px;
        display: block;
        width: 15px;
        height: 13px;
        background: url('../../../../../assets/icons/box-pink.svg') no-repeat
          center;
      }
    }
  }
}

.description {
  max-height: 95px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
  cursor: pointer;

  @media (max-width: $mobile) {
    max-height: unset;
    padding-left: 12px;
    width: 55%;
    border-radius: 0 10px 10px 0;
  }

  .title {
    margin: 0;
    margin-top: 8px;
    margin-left: 10px;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 20px;
  }

  address {
    margin: 0;
    font-size: 10px;
    color: $black;
  }

  .date {
    font-size: 10px;
    color: $black;
    opacity: 0.5;
    margin-left: 10px;
  }
  .publishButtonContainer {
    margin-top: -73px;
    margin-right: 15px;
    height: 80px;
    :active {
      background: darkgray;
    }
    :hover {
      // background: #b900e3;
    }
    @media (max-width: $mobile) {
      margin-top: -49px;
      margin-right: 0px;
    }
  }
  .publishButton {
    height: 37px;
    width: 135px;
    margin-top: 27px;
    float: right;
    color: white;
    font-size: 12px;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    background: #e544ff;
    @media (max-width: $mobile) {
      width: 110px;
    }
  }
  .publishedTourIndicator {
    margin-left: auto;
    margin-right: 0px;
    text-align: right;
    margin-top: 3px;
    font-size: 11px;
    line-height: 17px;
    @media (max-width: $mobile) {
      display: none;
    }
  }
  .publishedText {
    font-size: 18px;
    color: #e544ff;
    font-weight: 700;
  }
  .unpublishButton {
    margin-top: 5px;
    height: 37px;
    width: 105px;
    float: right;
    font-weight: 400;
    color: #8f9dba;
    font-size: 12px;
    border: solid #8f9dba 1px;
    box-shadow: none;
    border-radius: 5px;
    @media (max-width: $mobile) {
      width: 110px;
      margin-top: 27px;
    }
  }

  .views {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;

    &:after {
      margin-left: 4px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;

      background: url('../../../../../assets/icons/eye.svg') no-repeat center;
    }
  }
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.disabled {
  img {
    opacity: 0.5;
  }

  .description {
    opacity: 0.5;
  }

  .proLabel {
    color: rgba(229, 68, 255, 0.5);

    &:before {
      opacity: 0.5;
    }
  }
}

.first {
  // 480 - mobile
  @media (min-width: 481px) {
    &.container {
      max-width: 440px;
      width: 100%;
      height: 440px;
      grid-area: 1 / 1 / 3 / 3;
    }

    .tourLoadingWrapper {
      max-height: 440px;
      height: 100%;

      .overflow {
        .shareButton {
          top: 1rem;
          right: 1rem;
        }
      }
    }

    .tourImageWrapper {
      max-height: 440px;
      height: 100%;
      .overflow {
        .shareButton {
          top: 1rem;
          right: 1rem;
        }
      }
    }

    .description {
      max-height: 92px;
      padding: 18px 20px;
      flex-direction: row;
      justify-content: space-between;

      > div:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .date {
          width: 100px;
          text-align: end;
        }
      }
    }
  }
}

.titleDiv {
  max-width: 60%;
}
.isGenerated {
  pointer-events: all;
}

.isProcessing {
  pointer-events: all;
  :hover {
    cursor: not-allowed;
  }
  .title {
    max-width: 100%;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tourImageWrapper {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    img {
      filter: blur(5px) grayscale(100%);
    }
  }
  .shareButton {
    display: none;
  }
  .toggleWrapper {
    display: none !important;
  }
  .eyeButton {
    display: none;
  }
}
.isError {
  pointer-events: all;
  :hover {
    cursor: not-allowed;
  }
  .tourImageWrapper {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  .title {
    color: red;
  }
  .shareButton {
    display: none;
  }
  .toggleWrapper {
    display: none !important;
  }
  .eyeButton {
    display: none;
  }
}

// .toggleWrapper {
//   display: none !important;
// }
