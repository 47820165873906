@import 'src/variables';

.container {
  position: relative;

  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.875rem;

  background-color: $pink;

  @media (max-width: $tablet) {
    display: flex;
  }

  .joinBetaButton {
    font-weight: bold;
    line-height: 1.5rem;

    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.18);
  }

  & > button {
    position: absolute;
    top: 0;
    right: 0;

    margin: 0.75rem 1rem;
    padding: 0;
    background-color: inherit;
    border: none;
  }
}